<template>
  <oib-page>
    <oib-nav-student :allowLogout="true" :show_back="true">
      <template v-slot:header><slot name="header">
        <oib-header-search
        placeholder="Klanten zoeken"
        @search="search_query => run_query(search_query)"
        :search_frequency_ms="1"
        :show_back_button="false"
        :show_logout_button="true"
        >
        <template v-slot:on_empty_search v-if="has_role(['SYSTEM_SUPPORT', 'SYSTEM_ADMIN'])">
          <oib-button-icon
          icon="add"
          style="font-size: 24px;"
          @click="go_to_route('OrganisatieToevoegen')"
          />
        </template>
        </oib-header-search>
      </slot></template>
    </oib-nav-student>
    <div v-if="school_list.length == 0 && schools_loaded && search_query == ''" style="position: fixed; width: 100%;">
      <div class="wrapper column" style="height: 100vh; max-width: 300px; text-align: center; justify-content: center">
        <h3 style="margin-bottom: 0">Geen schoolpartners gevonden</h3>
        <p style="font-size: 0.8em">Vraag een school om de collectie te delen.</p>
      </div>
    </div>
    <div v-if="school_list.length == 0 && schools_loaded && search_query != ''" style="position: fixed; width: 100%;">
      <div class="wrapper column" style="height: 100vh; max-width: 300px; text-align: center; justify-content: center">
        <h3 style="margin-bottom: 0">Geen schoolpartners gevonden voor "{{search_query}}"</h3>
        <p style="font-size: 0.8em">Probeer opnieuw te zoeken met een andere spelling of een andere zoekterm.</p>
      </div>
    </div>
    <oib-content padding_top='padding_top'>
      <oib-swiper 
      style="padding-left: 5%"
      :style="$mq == 'desktop' ? 'margin-top: -45px; margin-bottom: 5px' : ''"
      >
        <div class="list-selector">
          <oib-button-label
            v-for="sort_obj in sort_list"
            :key="sort_obj.sort_var"
            :active="sort_mode.split(',')[0] == sort_obj.sort_var"
            @click="set_sort_mode(sort_obj.sort_var)"
          >{{get_label_text(sort_obj)}}</oib-button-label>
          <div style="width: 30px;"></div>
        </div>
      </oib-swiper>
      <oib-list-item-customer
        v-for="school in school_list"
        :key="school.organisation_id"
        :title="school.organisation_name"
        :subtext="school"
        :library_count="school.library_count"
        :copy_count="school.copy_count"
        :student_count="school.student_count"
        :employee_count="school.employee_count"
        :employee_count_active="school.employee_count_active"
        @click="load_school(school)"
      />
    </oib-content>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibContent from "../components/OibContent.vue";
import OibNavStudent from "../components/OibNavStudent.vue"
import OibHeaderSearch from "../components/oib_header_search.vue"
import OibListItemCustomer from "../components/oib_list_item_customer.vue"
import OibButtonLabel from "../components/OibButtonLabel.vue";
import OibSwiper from "../components/oib_swiper.vue"
import OibButtonIcon from "../components/OibButtonIcon.vue"

import {get_schools_customers_success_query} from "../store/api/customer_success.js"
import {attempt_school_partner_login} from "../store/user.js"
import {logout} from "../store/user"
import { has_role } from "../store/utils.js"
import { go_to_route } from "../store/navigation";

export default {
  name: "OverzichtScholen",
  components: {
    OibPage,
    OibContent,
    OibNavStudent,
    OibHeaderSearch,
    OibListItemCustomer,
    OibButtonLabel,
    OibSwiper,
    OibButtonIcon
  },
  data() {
    return {
      sort_list: [
        {
          'text_default': 'A-Z',
          'text_sorted_asc': 'A-Z',
          'text_sorted_desc': 'Z-A',
          'sort_var': 'organisation_name'
        },
        {
          'text_default': 'Laatst toegevoegd',
          'text_sorted_asc': '▼ Laatst toegevoegd',
          'text_sorted_desc': '▲ Laatst toegevoegd',
          'sort_var': 'organisation_creation_datetime'
        },
      ],
      sort_mode: 'organisation_creation_datetime,DESC',
      school_list: [],
      search_query: "",
      schools_loaded: false,
    }
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  methods: {
    // Import
    has_role,
    go_to_route,

    // Local
    get_label_text(sort_obj) {
      const sort_mode = this.sort_mode.split(',')
      const sort_var = sort_mode[0]
      const sort_dir = sort_mode[1]

      if (sort_obj.sort_var == sort_var) {
        return sort_dir == 'ASC' ? sort_obj.text_sorted_asc : sort_obj.text_sorted_desc
      } else {
        return sort_obj.text_default
      }
    },
    resetSortState() {
      for (let i = 0; i < this.sort_list.length; i++) {
        this.sort_list[i].active_key = 'text_default'
      }
    },
    set_sort_mode(sort_var_new){
      // Reset state of sort buttons
      this.resetSortState()

      // Set var ASC
      const sort_mode = this.sort_mode.split(',')
      if (sort_mode[0] != sort_var_new || sort_mode[1] == 'DESC') {
        this.sort_mode = sort_var_new + ',' + 'ASC'
        this.run_query('')
      }
      else if (sort_mode[1] == 'ASC') {
        this.sort_mode = sort_var_new + ',' + 'DESC'
        this.run_query('')
      }
    },
    async load_school(school) {
      
      // Logout from previous school
      if (this.$store.getters.get_school_id_active) {
        logout()
      }

      // Set new school
      this.$store.commit("set_organisation_id_active", school.organisation_id)
      this.$store.commit("set_school_id_active", school.school_id)

      // Log into school as partner
     await attempt_school_partner_login()

      this.$router.push({
        name: "Laden"
      });
    },
    async run_query(search_query) {

      console.log(search_query)

      this.schools_loaded = false
      this.search_query = search_query
      const organisation_id = this.$store.getters.get_organisation_id
      let query = organisation_id == 1 ? {} : {'organisation_id_partner': organisation_id}
      
      if (this.search_query.length > 0) {
        query['search'] = this.search_query
      } else {
        query['sort'] = this.sort_mode
      }

      const json = await get_schools_customers_success_query(this.$store.getters.get_jwt, query)
      let school_list = []
      json['customer_success_list'].forEach(school => school_list.push(school))
      this.school_list = school_list
      this.schools_loaded = true

      document.body.scrollTop = 0;
    }
  },
  async mounted() {
    if (!this.$store.getters.getUser) {
      return
    }

    // Add event listeners
    window.addEventListener('scroll', this.on_scroll);

    // Init
    this.run_query('')
  },
  unmounted() {
    window.removeEventListener('scroll', this.on_scroll);
  },
};
</script>

<style scoped>
.hide {
  display: none;
}

.list-selector {
  display: flex;
  flex-direction: row;
}

.list-selector button {
  margin-right: 10px;
}
</style>
