<template>
  <oib-page>
    <oib-nav-student :allowLogout="true" :show_back="false">
      <template v-slot:header><slot name="header">
        <oib-header-search
        placeholder="Leerlingen zoeken"
        @search="query => get_students_query_reset(query)"
        :search_frequency_ms="50"
        >
          <template v-slot:left>
            <oib-button-icon icon="person_add" @click="go_to_add_student_form" style="font-size: 24px" />
          </template>
          <template v-slot:search_collection>
            <oib-button-inline-classroom/>  
          </template>
          <template v-slot:on_empty_search>
            <oib-button-icon
              style="padding-right: 20px; font-size: 24px; margin-top: 2px"
              icon="qr_code_scanner"
              :active="false"
              @click="go_to_route('OverzichtLeerlingenQrCode')" />
          </template>
        </oib-header-search>
      </slot></template>
    </oib-nav-student>

    <oib-content padding_top='padding_top'>
      <div :style="$mq == 'desktop' ? 'margin-top: -47px;' : ''">
        <oib-tab-gebruikers
        v-if="has_role(['SCHOOL_ADMIN', 'SUPERVISOR', 'SYSTEM_SUPPORT', 'SYSTEM_ADMIN'])"
        style="margin-bottom: 10px"
        tab_active='leerlingen'
        />
        <oib-swiper 
        style="padding-left: 5%"
        :style="$mq == 'desktop' ? 'margin-top: 10px; margin-bottom: -5px;' : ''"
        >
          <div class="list-selector">
            <oib-button-label
              v-for="sort_obj in sortList"
              :key="sort_obj.sort_var"
              :active="$store.getters.get_student_list_sort_mode.split(',')[0] == sort_obj.sort_var"
              v-on:click="sortStudents(sort_obj.sort_var)"
            >{{get_label_text(sort_obj)}}</oib-button-label>
            <div style="width: 30px;"></div>
          </div>
        </oib-swiper>
      </div>
      <!-- QR-code hint-->
      <div class="wrapper column" v-if="displayed_tip == 'LOAD_STUDENTS'" style="margin-bottom: 10px; margin-top: 25px;">
        <div class="column" style="background-color: rgba(255,255,255, 0.03); padding: 10px 10px; max-width: 1000px; border-radius: 2px; justify-content: space-between; flex-wrap: wrap;">
          <h4 style="font-size: 0.9em; margin: 5px 0; padding-right: 10px;">Klik linksboven op <span class="material-icons" style="font-size: 1em;">person_add</span> om leerlingen in te laden.</h4>
        </div>
      </div>
      <!-- QR-code hint-->
      <div class="wrapper column" v-if="displayed_tip == 'LINK_STUDENT_QR_CODES'" style="margin-bottom: 10px; margin-top: 25px;">
        <div class="column" style="background-color: rgba(255,255,255, 0.03); padding: 10px 10px; max-width: 1000px; border-radius: 2px; justify-content: space-between; flex-wrap: wrap;">
          <div class="row" style="min-width: 360px; align-items: center;">
            <h4 style="font-size: 0.9em; margin: 0 0 0 0; padding-right: 10px;">Koppel de boekenleggers. Klik op</h4>
            <div class="row">
              <div
              class="row edit-button"
              style="background-color: var(--negative-color)"
              >
              <p style="font-size: 14px; margin-right: 5px;" class="material-icons">add</p>
                <p class="mono">QR-code</p>
              </div>
            </div>
          </div>
          <p style="font-size: 0.7em; width: 100%">Leerlingen kunnen zelf eenvoudig inloggen met de boekenlegger en leerkrachten kunnen door de boekenlegger te scannen makkelijk een leerling helpen.</p>
          <p style="font-size: 0.7em; width: 100%"><b>Tip:</b> Schrijf na het koppelen direct de naam van de leerling op de boekenlegger!</p>            
        </div>
      </div>
      <!-- AVI test hint-->
      <div class="wrapper column" v-if="displayed_tip == 'LOAD_READING_TESTS'" style="margin-bottom: 10px; margin-top: 25px;">
        <div class="column" style="background-color: rgba(255,255,255, 0.03); padding: 10px 10px; max-width: 1000px; border-radius: 2px; justify-content: space-between; flex-wrap: wrap;">
          <div class="row" style="min-width: 360px; align-items: center;">
            <h4 style="font-size: 0.9em; margin: 0 0 0 0; padding-right: 10px;">Upload de laatste leestoetsen. Klik op</h4>
            <div class="row">
              <div
              class="row edit-button"
              style="background-color: var(--negative-color)"
              @click="go_to_route('LvsNietMethodeToetsen')"
              >
              <p style="font-size: 14px; margin-right: 5px;" class="material-icons">add</p>
              <p class="mono">TOETS</p>
              </div>
            </div>
          </div>
          <p style="font-size: 0.7em; width: 100%">De leestoetsresultaten zijn essentieel voor de persoonlijke boektips en het monitoren van de betrokkenheid.</p>
          <p style="font-size: 0.7em; width: 100%">Laatste uploaddatum: {{ $store.getters.get_task_last_test_datetime ? $store.getters.get_task_last_test_datetime.slice(0, 10) : 'nooit' }}</p>
        </div>
      </div>

      <!-- No students found -->
      <div class="wrapper column" v-if="displayed_tip != 'LOAD_STUDENTS' && students_loaded && student_list.length == 0" style="margin-top: 15px">
        <div class="column" style="background-color: rgba(255,255,255, 0.03); padding: 10px 10px; max-width: 1000px; border-radius: 2px; justify-content: center; flex-wrap: wrap; text-align: center; height: 300px; align-items: center;">
          <h4 style="font-size: 0.9em; margin-top: 0; padding-right: 10px; margin-bottom: 5px">Geen leerlingen gevonden voor "{{ search_str }}"</h4>
          <p style="font-size: 0.7em; width: 100%">Probeer opnieuw te zoeken met een andere spelling of een andere zoekterm.</p>
        </div>
      </div>

      <div v-if="student_list.length > 0" style="margin-top: 20px;">
        <div
        v-for="student in student_list"
        :key="student.user_id"
        style="margin-bottom: 10px;"
        >
          <div class="wrapper row">
            <oib-book-cover
            v-if="student.isbn13"
            :isbn13="student.isbn13"
            style="margin-right: 0"
            @click="openOverzichtLeerling(student)"
            />
            <div
            v-if="!student.isbn13"
            class="img-frame"
            @click="openOverzichtLeerling(student)"
            >
              <div class="img-frame" :style="`display: flex; justify-content: center; align-items: center; background: var(--primary-color-p4); flex-direction: column; margin-right: 0`">
                <p style="font-size: 30px; color: var(--contrast-color-p3); font-weight: 400; margin: 0;">×</p>
              </div>
            </div>
            <div class="row" style="background-color: rgba(255,255,255, 0.03); padding: 10px 10px 10px 20px ; width: 100%; max-width: 930px; border-radius: 2px; justify-content: space-between; flex-wrap: wrap">
              <div class="row-text" @click="openOverzichtLeerling(student)">
                <h4 style="font-size: 0.9em; margin-top: 0; padding-right: 10px; margin-bottom: 5px">{{student.user_name}}</h4>
                <p style="font-size: 0.7em; margin-top: 0; margin-bottom: 0">{{ student.last_update ? student.last_update : 'Nieuw toegevoegd' }} • {{ student.classroom_name }}</p>
              </div>

              <div class="row">
                <div
                class="row edit-button"
                :style="student.has_id_token ? 'background-color: var(--highlight-color)' : 'background-color: var(--negative-color)'"
                @click="go_to_user_route(student.user_id, 'InstellingenQrCode')"
                >
                  <p style="font-size: 14px; margin-right: 5px;" class="material-icons">{{ student.has_id_token ? 'check' : 'add' }}</p>
                  <p class="mono">QR-code</p>
                </div>
    
                <!-- Up-to-date -->
                <div
                v-if="student.didactic_age_equivalent == 55 || (last_test_datetime >= target_test_date && student.test_type != null)"
                class="row edit-button"
                style="margin-left: 10px; background-color: var(--highlight-color)"
                @click="go_to_route('LvsNietMethodeToetsen')"
                >
                  <p style="font-size: 14px; margin-right: 5px;" class="material-icons">check</p>
                  <p class="mono"><span style="font-family: 'Roboto Mono'">{{ student.test_type }}</span>-{{ get_score_text(student) }}</p>
                </div>

                <!-- Out-of-date -->
                <div
                v-if="student.test_type == null || (student.didactic_age_equivalent < 55 && last_test_datetime < target_test_date)"
                class="row edit-button"
                style="margin-left: 10px; background-color: var(--negative-color)"
                @click="go_to_route('LvsNietMethodeToetsen')"
                >
                  <p style="font-size: 14px; margin-right: 5px;" class="material-icons">add</p>
                  <p class="mono" v-if="student.test_type">{{`${student.test_type}-${get_score_text(student)}`}}</p>
                  <p class="mono" v-if="!student.test_type">TOETS</p>
                </div>
              </div>
              <oib-button-icon
              class="icon"
              icon="chevron_right"
              style="font-size: 24px; color: var(--contrast-color-p4)"
              @click="openOverzichtLeerling(student)"
              />
            </div>
          </div>
          <div ref="bottom"/>
        </div>
      </div>
    </oib-content>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibContent from "../components/OibContent.vue";
import OibNavStudent from "../components/OibNavStudent.vue"
import OibButtonIcon from "../components/OibButtonIcon.vue"
import OibButtonLabel from "../components/OibButtonLabel.vue";
import OibSwiper from "../components/oib_swiper.vue"
import OibHeaderSearch from "../components/oib_header_search.vue"
import OibButtonInlineClassroom from '../components/oib_button_inline_classroom.vue'
import OibBookCover from '../components/oib_book_cover.vue'
import OibTabGebruikers from '../components/oib_tab_gebruikers.vue'

import {load_location_selectors} from '../store/user'
import {get_students} from "../store/api/reading_metrics"
import {get_book_cover} from "../store/api/library.js"
import {has_role} from "../store/utils.js"

function gtag () {
  window.dataLayer.push(arguments)
}

export default {
  name: "GebruikersLeerlingen",
  components: {
    OibPage,
    OibContent,
    OibNavStudent,
    OibButtonIcon,
    OibButtonLabel,
    OibSwiper,
    OibHeaderSearch,
    OibButtonInlineClassroom,
    OibBookCover,
    OibTabGebruikers
  },
  data() {
    return {
      sortList: [
        {
          'text_default': 'A-Z',
          'text_sorted_asc': 'A-Z',
          'text_sorted_desc': 'Z-A',
          'sort_var': 'user_name'
        },
        {
          'text_default': 'Laatst actief',
          'text_sorted_asc': '▼ Laatst actief',
          'text_sorted_desc': '▲ Laatst actief',
          'sort_var': 'last_update'
        },
        {
          'text_default': 'Leesniveau',
          'text_sorted_asc': '▼ Leesniveau',
          'text_sorted_desc': '▲ Leesniveau',
          'sort_var': 'didactic_age_equivalent'
        },
        {
          'text_default': 'QR-code',
          'text_sorted_asc': '✖ QR-code',
          'text_sorted_desc': '✔ QR-code',
          'sort_var': 'has_id_token'
        },
        {
          'text_default': 'Boek',
          'text_sorted_asc': '✖ Boek',
          'text_sorted_desc': '✔ Boek',
          'sort_var': 'has_book'
        }
      ],
      avi_dict: {
        0: 'START',
        5: 'M3',
        10: 'E3',
        15: 'M4',
        20: 'E4',
        25: 'M5',
        30: 'E5',
        35: 'M6',
        40: 'E6',
        45: 'M7',
        50: 'E7',
        55: 'PLUS'
      },
      avi_color: {
        0: '#EDBE00',
        5: '#E5B14F',
        10: '#BD2D22',
        15: '#82251E',
        20: '#BC1184',
        25: '#5F0CA2',
        30: '#392E96',
        35: '#3673B4',
        40: '#58AEDC',
        45: '#357344',
        50: '#5C9923',
        55: '#B1D034'
      },
      student_list: [],
      limit: 20,
      limit_downloaded: 20,
      page: 0,
      page_downloaded: null,
      search_str: "",
      students_loaded: false,
      last_test_datetime: null,
      target_test_date: null,
      target_test_name: '',
      missing_students: null,
      missing_qr_code: null,
      missing_avi_test: null
    };
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  created() {
    load_location_selectors()
  },
  async mounted() {
    // set scroll to top
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // Add event listeners
    window.addEventListener('scroll', this.on_scroll);

    // Init
    this.get_students_query_reset('')
  },
  unmounted() {
    window.removeEventListener('scroll', this.on_scroll);
  },
  methods: {
    // Import
    has_role,

    // Local
    gtag_event(event_name) {
      gtag('event', event_name, {
        'app_name': 'Onderwijs in Beeld - app',
        'screen_name': 'OverzichtLeerlingen'
      });
    },
    go_to_user_route(user_id, route) {
      this.$router.push({
        name: route,
        params: {
          user_id: user_id
        },
      });
    },
    get_label_text(sort_obj) {
      const sort_mode = this.$store.getters.get_student_list_sort_mode.split(',')
      const sort_var = sort_mode[0]
      const sort_dir = sort_mode[1]

      if (sort_obj.sort_var == sort_var) {
        return sort_dir == 'ASC' ? sort_obj.text_sorted_asc : sort_obj.text_sorted_desc
      } else {
        return sort_obj.text_default
      }
    },
    go_to_route(route) {
      this.$router.push({
        name: route
      });
    },
    on_scroll() {
      // Have we reached the bottom?
      //let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight;
      if (this.$refs.bottom && this.has_reached_bottom(this.$refs.bottom)) {

        // And previous page loaded -> load new search_page
        if ((this.page + 1) * this.limit == this.student_list.length) {
          this.page += 1;
          this.get_students_query()
          }
      }
    },
    get_score_text(student) {
      if (!student['last_test_datetime']) {
        return null
      }
      if (student['didactic_age_equivalent'] == null) {
        return '●●●'
      }
      return this.avi_dict[student['didactic_age_equivalent']]
    },
    has_reached_bottom(el) {
        var rect = el.getBoundingClientRect();
        this.debug_title = rect.bottom + '-' + (window.innerHeight | document.documentElement.clientHeight);

        return rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    },
    async get_students_query_reset(search_query) {
      this.page = 0
      this.page_downloaded = null
      this.limit_downloaded = this.limit
      this.students_loaded = false
      this.student_list = []

      this.search_str = search_query ? search_query : ''
      await this.get_students_query()
    },
    async get_students_query() {
      
      // Return if page was refreshed
      const user = this.$store.getters.getUser
      if (!user) return

      // Return if this query was already executed
      if (this.page == this.page_downloaded) return
      this.page_downloaded = this.page

      // Return if limit was reached
      if (this.limit > this.limit_downloaded) return

      // Get active location selector
      const selector_list = this.$store.getters.get_location_selector_list
      const selector_active_index = this.$store.getters.get_location_selector_active
      const selector_active = selector_list[selector_active_index]
      
      // Skip if no active selector found
      if (selector_active == undefined || selector_active.query.classroom_id == '') {
        return
      }

      this.students_loaded = false
      
      // Build query
      let query = selector_active.query
      query['limit'] = this.limit
      query['page'] = this.page
      query['sort'] = this.$store.getters.get_student_list_sort_mode
      query['schoolyear'] = this.$store.getters.get_schoolyear

      if (this.search_str.length > 0) {
        query['search'] = this.search_str
      } else if ('search' in query) {
        delete query['search']
      }

      // Execute query
      const jwt = this.$store.getters.getJwt
      const json = await get_students(jwt, query)

      this.students_loaded = true

      json['student_list'].forEach(student => {
        this.student_list.push(student)
        student.role = ['STUDENT']
        this.$store.commit("add_user", student)
      });

      // Sync tasks
      const student_list = this.$store.getters.getStudents
      if (student_list.length >= 20) {
        this.$store.dispatch("sync_task_student_list", {
          'jwt': jwt,
          'organisation_id': this.$store.getters.get_organisation_id_active,
          'student_list': student_list
        })
      }
    },
    getCoverUrl(isbn13) {
      if (isbn13) return get_book_cover(isbn13, 's')
      return "https://us.123rf.com/450wm/teploleta/teploleta1801/teploleta180100105/94540385-seamless-vector-pattern-with-doodle-questions-marks-on-a-blackboard-background.jpg?ver=6";
    },
    async openOverzichtLeerling(student) {
      // Go to student overview
      this.$router.push({
        name: "OverzichtLeerling",
        params: {
          user_id: student.user_id,
        },
      });
    },
    resetSortState() {
      for (let i = 0; i < this.sortList.length; i++) {
        this.sortList[i].active_key = 'text_default'
      }
    },
    sortStudents(sort_var_new){
      // Reset state of sort buttons
      this.resetSortState()

      // Set var ASC
      const sort_mode = this.$store.getters.get_student_list_sort_mode.split(',')
      if (sort_mode[0] != sort_var_new || sort_mode[1] == 'DESC') {
        this.$store.commit("set_student_list_sort_mode", sort_var_new + ',' + 'ASC')
        this.get_students_query_reset()
      }
      
      else if (sort_mode[1] == 'ASC') {
        this.$store.commit("set_student_list_sort_mode", sort_var_new + ',' + 'DESC')
        this.get_students_query_reset()
      }

      this.gtag_event("sort_students_" + this.$store.getters.get_student_list_sort_mode)
    },
    go_to_add_student_form() {
      this.$router.push({
        name: 'LvsKoppelenAutorisatie',
        params: {
          user_group: 'leerlingen'
        }
      })
    }
  },
  computed: {
    location_selector_name(){
      const selector_list = this.$store.getters.get_location_selector_list
      const selector_active_index = this.$store.getters.get_location_selector_active
      const selector_active = selector_list[selector_active_index]
      return selector_active != undefined ? selector_active.name : null
    },
    students_available() {
      return !this.students_loaded || this.student_list.length > 0
    },
    displayed_tip() {
      const task_dict = this.$store.getters.get_task_dict
      const task_list = ['LOAD_STUDENTS', 'LINK_STUDENT_QR_CODES', 'LOAD_READING_TESTS']
      for (let i = 0; i < task_list.length; i++) {
        if (task_list[i] in task_dict && task_dict[task_list[i]].finish_datetime == null) {
          return task_list[i]
        }
      }
      return null
    }
  }
};
</script>

<style scoped>
.hide {
  display: none;
}

.list-selector {
  display: flex;
  flex-direction: row;
}

.list-selector button {
  margin-right: 10px;
}

.edit-button {
  padding: 2px 5px;
  border-radius: 4px;
  margin: 7px 0;
  align-items: center;
  min-width: 85px
}

.edit-button p {
  color: white;
  font-weight: 600;
  margin: 0;
}

.edit-button:hover{
  cursor: pointer;
}

.img-frame {
  width: 78px;
  height: 100px;
  object-fit: cover;
  border-radius: 2px;
}

@media screen and (min-width:450px) {

  .row-text {
    min-width:310px
  }
}

@media screen and (min-width:640px) {
  .img-frame {
    width: 50px;
    height: 62px;
    object-fit: cover;
    border-radius: 2px;
  }
}

.user-feedback {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;  
}

.user-feedback div {
  justify-content: center;
  text-align: center;
  max-width: 380px;
  height: 100%;
}

@media screen and (min-width:992px) {
  .user-feedback {
    width: calc(100% - 200px)
  }
}

.mono {
  font-size: 12px;
  /* font-family: 'Roboto Mono'; */
}
</style>
