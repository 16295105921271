<template>
  <div>
    <oib-form-lvs-groups @submit="x => submit(x)"/>
    <oib-modal-loading ref="modal_loading" :progress="progress" :text="text"/>
  </div>
</template>

<script>
import OibFormLvsGroups from "../components/oib_form_lvs_groups.vue";
import OibModalLoading from '../components/oib_modal_loading.vue'

import {put_schools_lvs} from "../store/api/lvs.js"
import {post_schools_lvs_sync} from "../store/api/lvs.js"
import {get_students} from "../store/api/reading_metrics"

function get_equals_set (a, b) {
  return a.size === b.size && [...a].every(value => b.has(value))
}

function get_intersection_set (a, b) {
  return new Set([...a].filter(x => b.has(x)))
}

function get_union_set (a, b) {
  return new Set([...a, ...b])
}

export default {
  name: "LvsKoppelenGroepen",
  components: {
    OibFormLvsGroups,
    OibModalLoading
  },
  data() {
    return {
      valid_authorization_key: null,
      classroom_list: null,
      progress: 0,
      text: "",
      classroom_diff_init: -1,
      max_wait_time: 35
    }
  },
  methods: {
    async submit(body) {

      // Skip if body was undefined
      if (!body) {
        return
      }

      this.$refs.modal_loading.show()

      this.classroom_list = body['classroom_list']
      const jwt = this.$store.getters.get_jwt
      const school = this.$store.getters.get_school
      const branch_number = school['branch_number']
      const lvs_link = this.$store.getters.get_lvs_link

      // Reset
      this.classroom_diff_init = -1
      this.text = "Leerlingen synchroniseren ..."

      // Update lvs connection on server and client side
      await put_schools_lvs(
          jwt,
          branch_number,
          lvs_link['lvs_type'],
          lvs_link['authorization_key'],
          this.classroom_list
      )

      this.$store.commit('set_lvs_link', {
        'branch_number': branch_number,
        'lvs_type': lvs_link['lvs_type'],
        'authorization_key': lvs_link['authorization_key'],
        'classroom_list': this.classroom_list,
        'lvs_creation_datetime': lvs_link['lvs_creation_datetime']
      })

      // Sync lvs
      post_schools_lvs_sync(jwt, branch_number, lvs_link['lvs_type'], 1, 0)

      // Async await loading of classrooms and refresh jwt on loaded if classroom list changed
      if (get_equals_set(new Set(lvs_link.classroom_list), new Set(this.classroom_list))) {
        this.$router.back()
      } else {
        this.validate(0)
      }
    },
    async validate(index) {
      const jwt = this.$store.getters.get_jwt
      const organisation_id = this.$store.getters.get_organisation_id_active

      const json = await get_students(jwt, {
        'organisation_id': organisation_id,
        'schoolyear': this.$store.getters.get_schoolyear,
        'limit': 3000,
        'page': 0
      })

      // Compare requested classrooms to loaded classrooms
      const classroom_set = new Set(this.classroom_list)
      let classroom_loaded_set = new Set()
      json['student_list'].forEach(x => classroom_loaded_set.add(x.classroom_name))

      const intersection = get_intersection_set(classroom_set, classroom_loaded_set)
      const union = get_union_set(classroom_set, classroom_loaded_set)

      const classroom_diff = union.size - intersection.size
      if (this.classroom_diff_init == -1) {
        this.classroom_diff_init = classroom_diff
      }

      this.progress = (this.classroom_diff_init - classroom_diff) / this.classroom_diff_init * 50

      if (get_equals_set(classroom_set, classroom_loaded_set)) {
        // Add additional delay to allow for all students to buffer
        this.text = "Leerlingen synchroniseren voltooid"
        setTimeout(this.on_classrooms_loaded, 3000)
      } else  if (index < this.max_wait_time / 4) {
        setTimeout(this.validate, 1000, index + 1)
      } else  if (index < this.max_wait_time / 2) {
        this.text = "Nog even geduld ..."
        setTimeout(this.validate, 1000, index + 1)
      } else if (index < this.max_wait_time) {
        this.text = "Dit duurt erg lang... Neem contact op."
        setTimeout(this.$refs.modal_loading.hide, 4000)
      }
    },
    async on_classrooms_loaded() {
      this.progress = 100
      await this.$store.dispatch("jwt_refresh_resources")
      setTimeout(this.$router.back, 500)
    }
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  }
};
</script>
