<template>
  <oib-form :form_step_validation_list="form_step_validation_list" title="Bibliotheek verhuizen" @submit="submit">
    <div>
      <oib-input-text
      label="Naam van de school"
      placeholder="Jenaplanschool Wittevrouwen"
      :required="true"
      :error_msg="organisation_name_error"
      description="Type de naam van de school waarnaar je de bibliotheek wilt verplaatsen. Je vindt de naam van de school in het scholen overzicht."
      @input="set_organisation_name($event.target.value)"
      />
    </div>
  </oib-form>
</template>

<script>
import OibForm from "../components/oib_form/oib_form.vue";
import OibInputText from "../components/oib_form/oib_input_text.vue"

import { get_libraries_books_copies } from "../store/api/library.js"
import { get_schools_customers_success_query } from "../store/api/customer_success.js";
import { get_libraries_query } from "../store/api/library.js";
import { put_organisations_libraries } from "../store/api/library.js";
import { reload_libraries } from "../store/library";

export default {
  name: "BibliotheekVerplaatsenOrganisatie",
  props: ['library_id'],
  components: {
    OibForm,
    OibInputText
  },
  data() {
    return {
      form_step_validation_list: [false],
      organisation_name: "",
      organisation_name_error: "",
      organisation_dict: {},
      library: null,
      copy_list: []
    }
  },
  async created() {
    const jwt = this.$store.getters.get_jwt
    const organisation_id = this.$store.getters.get_organisation_id
    const query = organisation_id == 1 ? {} : {'organisation_id_partner': organisation_id}
    let json = await get_schools_customers_success_query(jwt, query)

    json['customer_success_list'].forEach(school => {
      this.organisation_dict[school['organisation_name'].toLowerCase()] = school['organisation_id']
    })

    json = await get_libraries_query(jwt, {
      'library_id': this.library_id
    })
    const library_list = json['library_list']
    if (library_list.length == 0) {
      this.$store.router.back()
    }
    this.library = library_list[0]

    json = await get_libraries_books_copies(jwt, {
      'library_id': this.library_id,
      'is_borrowed': 1
    })
    this.copy_list = json['copy_list']
  },
  methods: {
    set_organisation_name(value) {
      this.organisation_name = value.toLowerCase()
      this.val_step_1()
    },
    async val_step_1() {
      if (this.copy_list.length > 0) {
        this.organisation_name_error = "Niet alle boeken zijn ingeleverd."
      } else if (!(this.organisation_name in this.organisation_dict)) {
        this.organisation_name_error = "Deze schoolnaam is onbekend."
      } else {
        this.organisation_name_error = null
      }

      this.form_step_validation_list[0] = this.organisation_name_error == null
    },
    async submit() {

      const jwt = this.$store.getters.get_jwt
      const organisation_id_owner_new = this.organisation_dict[this.organisation_name]

      // Update library
      const response = await put_organisations_libraries(
          jwt,
          this.$store.getters.get_organisation_id_active,
          this.library_id,
          organisation_id_owner_new,
          this.library.library_name,
          this.library.location_name,
          this.library.creation_datetime
      )

      await reload_libraries()

      if (response.status == 204) {
        this.$router.push({
          name: 'BibliotheekCollectie',
          params: {
            user_id: this.$store.getters.getUser['user_id']
          }
        });
      } else if (response.status == 409) {

      }
    }
  }
};
</script>
